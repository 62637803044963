<!--健康检查-->
<template>
	<div class="index">
		ok
	</div>
</template>
<script>
    export default {
        data () {
            return {

            }
        },
        methods: {

        },
        mounted() {

        }
    };
</script>
